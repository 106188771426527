/*
  Simple Class to globally keep track of processes.
*/
export default class ProcessMonitor {
  processing = {};

  getProcessID(deal) {
    return `${deal.dealID}|${deal.currentVersion.key}`;
  }

  isProcessing(deal) {
    return !!this.processing[this.getProcessID(deal)];
  }

  process(deal) {
    this.processing[this.getProcessID(deal)] = true;
  }
}
