/* eslint-disable no-console */
import Sleep from './Sleep';

class Walker {
  constructor(items, action, opts = {}) {
    // core params
    this.items = items;
    this.action = action;

    if (!this.items) throw new Error('You must pass "items" to the sequencer.');
    if (!this.action) throw new Error('You must pass an "action" to the sequencer.');

    // options
    this.waitTime = opts.waitTime || 200;
    this.verbal = !!opts.verbal || false;
    this.limit = opts.limit || 0;

    // state props
    this.processedCount = 0;
    this.isProcessing = null;
    this.isDone = false;
  }

  log(...args) {
    this.verbal && console.log('Walker :: ', ...args);
  }
  logTime(...args) {
    this.verbal && console.time('Walker :: ', ...args);
  }
  logTimeEnd(...args) {
    this.verbal && console.timeEnd('Walker :: ', ...args);
  }

  get isRunCompleted() {
    return this.processedCount === this.items.length;
  }

  get isLimitReached() {
    return !!this.limit && this.processedCount === this.limit;
  }

  start() {
    this.runPromise = new Promise((resolve, reject) => {
      this.runPromiseResolve = resolve;
      this.runPromiseReject = reject;
    });

    this.process();

    this.runPromise.then(() => this.log('Intervaler :: All Done !'));

    return this.runPromise;
  }

  async process() {
    this.log('process()', {
      isProcessing: this.isProcessing,
      isDone: this.isDone,
      processedCount: this.processedCount,
    });

    if (this.isProcessing === true) return;

    if (this.isLimitReached || this.isRunCompleted) {
      this.isDone = true;
      this.runPromiseResolve(this.processedCount);
      return;
    }

    // Make sure we wait for either minimum this.waitTime or for the action to be completed.
    this.logTime(`Processing item[${this.processedCount}]`);
    this.isProcessing = true;
    await Promise.all([
      await Sleep(this.waitTime),
      await this.action(this.items[this.processedCount], this.processedCount),
    ]);

    this.processedCount++;
    this.isProcessing = false;
    this.logTimeEnd(`Processing item[${this.processedCount}]`);

    this.process();
  }
}

export default Walker;
