// https://24ways.org/2010/calculating-color-contrast/
// https://en.wikipedia.org/wiki/YIQ
export const getContrastYIQ = (hexcolor) => {
  hexcolor = hexcolor.replace('#', '');

  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);

  // Make sure we fallback to black text if we get some weird invalid hex values
  if (isNaN(r) || isNaN(g) || isNaN(b)) return 'black';

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};
