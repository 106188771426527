export const rotatedImgAnchors = (pageWidth, pageHeight, imgWidth, imgHeight, rotation) => {
  let rotatedAnchors;

  switch (rotation - 0) {
    case 0:
      rotatedAnchors = [
        [
          [0, 0],
          [(pageWidth - imgWidth) / 2, 0],
          [pageWidth - imgWidth, 0],
        ],
        [
          [0, (pageHeight - imgHeight) / 2],
          [(pageWidth - imgWidth) / 2, (pageHeight - imgHeight) / 2],
          [pageWidth - imgWidth, (pageHeight - imgHeight) / 2],
        ],
        [
          [0, pageHeight - imgHeight],
          [(pageWidth - imgWidth) / 2, pageHeight - imgHeight],
          [pageWidth - imgWidth, pageHeight - imgHeight],
        ],
      ];
      break;
    case 90:
      rotatedAnchors = [
        [
          [0, -imgHeight], //Origin
          [0, -(pageWidth + imgHeight) / 2],
          [0, -pageWidth],
        ],
        [
          [(pageHeight - imgWidth) / 2, -imgHeight],
          [(pageHeight - imgWidth) / 2, -(pageWidth + imgHeight) / 2],
          [(pageHeight - imgWidth) / 2, -pageWidth],
        ],
        [
          [pageHeight - imgWidth, -imgHeight],
          [pageHeight - imgWidth, -(pageWidth + imgHeight) / 2],
          [pageHeight - imgWidth, -pageWidth],
        ],
      ];
      break;
    case 180:
      rotatedAnchors = [
        [
          [-imgWidth, -imgHeight], //Origin
          [(-pageWidth - imgWidth) / 2, -imgHeight],
          [-pageWidth, -imgHeight],
        ],
        [
          [-imgWidth, -(pageHeight - imgHeight) / 2],
          [(-pageWidth - imgWidth) / 2, -(pageHeight - imgHeight) / 2],
          [-pageWidth, -(pageHeight - imgHeight) / 2],
        ],
        [
          [-imgWidth, -pageHeight],
          [(-pageWidth - imgWidth) / 2, -pageHeight],
          [-pageWidth, -pageHeight],
        ],
      ];
      break;
    case 270:
    case -90:
      rotatedAnchors = [
        [
          [-imgWidth, 0], //Origin
          [-imgWidth, (pageWidth - imgHeight) / 2],
          [-imgWidth, pageWidth - imgHeight],
        ],
        [
          [-(pageHeight + imgWidth) / 2, 0],
          [-(pageHeight + imgWidth) / 2, (pageWidth - imgHeight) / 2],
          [-(pageHeight + imgWidth) / 2, pageWidth - imgHeight],
        ],
        [
          [-pageHeight, 0],
          [-pageHeight, (pageWidth - imgHeight) / 2],
          [-pageHeight, pageWidth - imgHeight],
        ],
      ];
      break;
  }

  return rotatedAnchors;
};

export const rotatedAnchorMargins = (rotation) => {
  let rotatedMargins;

  // prettier-ignore
  switch (rotation - 0) { 
    case 0:
    default:
        rotatedMargins = [
        [[1, 1], [0, 1], [-1, 1]],
        [[1, 0], [0, 0], [-1, 0]],
        [[1, -1], [0, -1], [-1, -1]],
        ];
        break;
    case 90:
        rotatedMargins = [
        [[1, -1], [1, 0], [1, 1]],
        [[0, -1], [0, 0], [0, 1]],
        [[-1, -1], [-1, 0], [-1, 1]],
        ];
        break;
    case 180:
        rotatedMargins = [
        [[-1, -1], [0, -1], [1, -1]],
        [[-1, 0], [0, 0], [1, 0]],
        [[-1, 1], [0, 1], [1, 1]],
        ];
        break;
    case -90:
    case 270:
        rotatedMargins = [
        [[-1, 1], [-1, 0], [-1, -1]],
        [[0, 1], [0, 0], [0, -1]],
        [[1, 1], [1, 0], [1, -1]],
        ];
        break;
    }

  return rotatedMargins;
};

export const IMAGE_SIZES = {
  xsmall: [50, 50],
  small: [100, 100],
  medium: [200, 200],
  large: [300, 300],
  xlarge: [400, 401],
};
